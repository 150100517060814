.default-widget-modal {
  .modal-dialog {
    &.modal-default {
      max-width: 560px !important;
    }
  }
  .widget-icon-tooltip {
    top: 0 !important;
  }

  dropzone > .dropzone.dz-wrapper .dz-message .dz-text {
    font-size: 20px;
  }

  @media only screen and (min-width: 576px) {
    max-width: 560px !important;
  }
  .modal-content {
    background: #ffffff;
    box-shadow: 0 8px 48px rgba(24, 23, 24, 0.08);
    border-radius: 4px;
  }

  .modal-header {
    display: block;
    width: 100%;
    min-height: 64px;
    background: #f6f6fc !important;
    border-radius: 4px 4px 0 0;
    padding: 16px 40px;
    border: none;

    .modal-title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #181718;
    }

    .btn-close {
      width: 18px !important;
      height: 18px !important;
      font-size: 18px !important;
      padding: 0;
      margin: 0;
      position: absolute;
      top: 26px;
      right: 26px;
    }
  }

  .modal-body {
    padding: 32px 40px 0px 40px;
    @media (max-width: 575px) {
      padding: 20px 24px 0px 24px;
    }

    .modal-text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #181718;

      margin-bottom: 24px;
    }

    .col-form-label {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #181718;

      padding: 0 0 8px 0;
    }

    .form-control {
      height: 40px;
      border: 1px solid #d7d7d9;
      border-radius: 4px;
    }
  }

  .modal-footer {
    padding: 0 20px 20px 20px;
    border: none;
    @media (max-width: 575px) {
      padding: 10px;
    }

    .btn {
      background: var(--bs-primary);
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;

      &.btn-outline {
        background: transparent;
        border-color: var(--bs-primary);
        color: var(--bs-primary);

        &:hover {
          background: var(--bs-primary);
          color: #ffffff;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .default-widget-modal {
    min-height: calc(100vh - 24px);
  }
}
