.table-custom-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.table-custom {
  display: flex;
  width: 100%;
  border-spacing: 0;

  tbody {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    height: 40px;

    & + .table-custom__row {
      &:before {
        content: "";
        height: 1px;
        width: 100%;

        position: absolute;
        top: 0;
        left: 0;
        background-color: #d7d7d9;
      }
    }
  }

  &__title {
    display: flex;
    float: left;
    width: 120px;
    padding: 9px 0;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #595859;
  }

  &__value {
    display: flex;
    float: right;
    max-width: calc(100% - 120px);
    padding: 9px 0;

    &-text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #181718;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
    }
  }
}
