@import "@fontsource/inter";
@import "assets/scss/modal";
@import "assets/scss/modal-default";
@import "assets/scss/spinner";

.tooltip.show {
  opacity: 1 !important;
}

body {
  background-color: #ebebf4 !important;
  font-family: "Inter" !important;
  color: #181718 !important;
  font-weight: 400 !important;
}

.hide-opacity {
  opacity: 0;
  pointer-events: none;
}

.page-title-box.lowercase h4 {
  text-transform: none !important;
  font-size: 20px !important;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.font-bold,
strong {
  font-weight: 600 !important;
  color: #181718 !important;
}

.btn-location {
  margin-left: -30px;
}

.opacity:hover {
  opacity: 0.6;
}

.page-content {
  min-height: 100vh;
}

app-header-select-location .dropdown-item {
  white-space: normal !important;
}

.height-xs {
  max-width: 100%;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  overflow: hidden;
  max-width: 600px;
}

@media only screen and (max-width: 1400px) {
  .height-xs {
    max-width: 660px;
  }
}

@media only screen and (max-width: 1200px) {
  .height-xs {
    max-width: 450px;
  }
}

@media only screen and (max-width: 1000px) {
  .height-xs {
    max-width: 430px;
  }
}

@media only screen and (max-width: 950px) {
  .height-xs {
    max-width: 410px;
  }
}

@media only screen and (max-width: 900px) {
  .height-xs {
    max-width: 400px;
  }
}

@media only screen and (max-width: 850px) {
  .height-xs {
    max-width: 300px;
  }
}

@media only screen and (max-width: 800px) {
  .height-xs {
    max-width: 250px;
  }
}

@media only screen and (max-width: 750px) {
  .height-xs {
    max-width: 220px;
  }
}

@media only screen and (max-width: 700px) {
  .height-xs {
    max-width: 200px;
  }
}

@media only screen and (max-width: 600px) {
  .page-content {
    padding: 105px 0 60px 0 !important;
  }

  #page-header-user-dropdown {
    padding-top: 20px !important;
  }

  .dropdown-item-text {
    white-space: normal;
  }

  .height-xs {
    margin-top: 10px;
    height: 50px !important;
    max-width: 100% !important;
    white-space: normal !important;
    text-overflow: clip !important;
  }

  .btn-location {
    margin-left: 0px !important;
  }
  .icon-map {
    margin-left: 0px !important;
  }

  app-header-select-location .dropdown-item {
    min-width: 100% !important;
  }
}

@media only screen and (max-width: 550px) {
  .height-xs {
    max-width: 100% !important;
    white-space: normal !important;
    text-overflow: clip !important;
  }
  .btn-location {
    margin-left: 0px !important;
  }
  .icon-map {
    margin-left: 0px !important;
  }
  app-header-select-location .dropdown-item {
    min-width: 100% !important;
  }
}

@media only screen and (max-width: 500px) {
  .height-xs {
    max-width: 100% !important;
    white-space: normal !important;
    text-overflow: clip !important;
  }
  .btn-location {
    margin-left: 0px !important;
  }
  .icon-map {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 450px) {
  .height-xs {
    max-width: 100% !important;
    white-space: normal !important;
    text-overflow: clip !important;
  }
  .btn-location {
    margin-left: 0px !important;
  }
  .icon-map {
    margin-left: 0px !important;
  }

  app-header-select-location .dropdown-item {
    min-width: 100% !important;
  }
}

@media only screen and (max-width: 400px) {
  .height-xs {
    max-width: 100% !important;
    white-space: normal !important;
    text-overflow: clip !important;
  }

  .btn-location {
    margin-left: 0px !important;
  }
  .icon-map {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 350px) {
  .height-xs {
    max-width: 100% !important;
  }
  .icon-map {
    margin-left: 0px;
  }
  .btn-location {
    margin-left: 0px;
  }
}

.header-item {
  padding: 0 24px;
}

.active {
  font-weight: 600 !important;
  color: #181718 !important;
}

body.modal-open {
  padding-right: 0 !important;
  overflow: auto !important;
}

.btn-outline-primary {
  color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  font-weight: 600 !important;
}

.btn-primary {
  background-color: var(--bs-primary) !important;
  font-weight: 600 !important;
}

app-qr-code-generator-button .btn-primary {
  width: 100% !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: var(--bs-primary) !important;
}

.modal-default {
  max-width: 560px !important;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #cfd4d9;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.content-not-active-tooltip .tooltip-inner {
  opacity: 1;
}

.content-active-tooltip .tooltip-inner {
  display: none !important;
  opacity: 0 !important;
}

.simple .btn-primary {
  background-color: #f6f6fc !important;
  color: #181718 !important;
  border-color: #f6f6fc !important;
  height: 40px;
  font-weight: 600px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  opacity: 1 !important;
  pointer-events: all !important;
}

.simple .btn-primary:hover {
  background-color: #e8e8ef !important;
}

.simple .coupon-title {
  white-space: normal !important;
}

:root .ng-select .ng-dropdown-panel .ng-option.ng-option-marked {
  background-color: var(--bs-primary) !important;
}

.card-form-content {
  background-color: #fff !important;
  min-height: calc(100vh - 180px) !important;
  padding-bottom: 80px !important;
}

.modal-backdrop.show {
  opacity: 0.85 !important;
}

.modal-open {
  ::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    background: dimgray !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #000000 !important;
    -webkit-border-radius: 1ex;
  }
}

:root mark {
  color: var(--bs-primary);
  background: transparent !important;
  font-weight: 600;
  padding: 0 !important;
}
